<template>
  <div class="table" style="padding-bottom:10px">
    <div class="e-breadcrumb"><router-link :to="{path: '/members/member'}">会员管理</router-link> > 会员详情</div>
    <div class="container">
      <el-form ref="form" label-width="130px">
        <div style="float:left;width: 5px;height: 20px; background: #66b1ff;margin-right: 15px;"></div>
        <span style="font-size: 20px;">会员信息 </span>
        <br/><br/>

        <div style="margin-top: 50px">
          <div style="margin-left: 70px;float: left"><span><img v-if="member.headPortrait" :src="member.headPortrait" class="avatar" style="width: 100px;height: 100px"></span></div>
          <div style="margin-left: 200px">
            <span style="font-family: 'Arial Negreta', 'Arial Normal', 'Arial';font-weight: 700;font-style: normal;font-size: 28px;color: #000000">{{member.nickName}}</span>
            <div style="margin-top:35px;font-family: 'Arial Normal', 'Arial';font-weight: 400;font-style: normal">
              <span>注册时间</span>
              <div>
                {{member.createTime}}
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 70px;" class="text">
          <div style="clear: left; margin-left: 140px;">
            <el-row :gutter="20">
              <el-col :span="1">编号</el-col>
              <el-col :span="1"><span>{{member.serial}}</span></el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 40px">
              <el-col :span="1">性别</el-col>
              <el-col :span="1">
                <span v-if="member.sex === 0">女</span>
                <span v-if="member.sex === 1">男</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 40px">
              <el-col :span="1" style="padding-left: 0px">手机号</el-col>
              <el-col :span="1">
                <span>{{member.phone}}</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 40px">
              <el-col :span="1">邮箱</el-col>
              <el-col :span="1">
                <span>{{member.email}}</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 40px">
              <el-col :span="1">状态</el-col>
              <el-col :span="1">
                <span v-if="member.status === 0">启用</span>
                <span v-if="member.status === 1">禁用</span>
              </el-col>
            </el-row>
          </div>
        </div>

        <div style="margin-top: 80px;height: 100%" v-if="address.length != 0">
          <div style="float:left;width: 5px;height: 20px; background: #66b1ff;margin-right: 15px;"></div>
          <span style="font-size: 20px;">收件人信息</span>
          <br/><br/>
          <div style="margin-left: 70px;">
            <div style="margin-top:40px; width: 1422px;height: 100%;background: inherit;background-color: rgba(245, 246, 250, 1);box-sizing: border-box;border-width: 1px;border-style: solid;border-color: rgba(228, 228, 228, 1);border-radius: 0px;">
              <div class="e-table-wrapper">
                <table cellspacing="0" cellpadding="0" border="0" width="100%" style="text-align: center">
                  <thead>
                  <tr>
                    <th class="th-l" style="text-align: center">收件人</th>
                    <th class="th-l" style="text-align: center">联系方式</th>
                    <th class="th-l" style="text-align: center">地址</th>
                    <th class="th-l" style="text-align: center">是否默认地址</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row,index) in address" :key="row.id">
                      <td class="td-l">{{row.name}}</td>
                      <td class="td-l">{{row.mobile}}</td>
                      <td class="td-l">{{row.detailAdd}}</td>
                      <td class="td-l">
                        <span v-if="row.isDefault == 1">是</span>
                        <span v-if="row.isDefault == 0">否</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>

export default {
    data() {
        return {
            id : '',
            member: '',
            address: []
        }
    },
    async created() {
        this.id = this.$route.query.id
        await this.getMemberInfo()
    },
    methods: {
        async getMemberInfo() {
            let data = {
                id: this.id,
            }
            let res = await this.$post("/admin/getMemberById", data)
            if (res.code == 200) {
                this.member = res.member
                this.address = res.address
            }
        },
        backEvent(){
            this.$router.push('/members/member')
        }
    }
}
</script>
<style lang="scss">
  .title{
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    color: #333333;
  }
  .content{
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    color: #333333;
  }
  .text {
    .title{
      text-align: right;
    }
    .content{
      text-align: left;
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #d9d9d9;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>